import React from "react";

export default function AboutUs(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>About Us</h1>
					<AboutUsContent />
				</div>
			</section>
		</main>
	);
}

export function AboutUsContent(): JSX.Element {
	return (
		<main>
			{/* <h1>About Us</h1> */}
			<div className="card">
				<h2>
					Notaduo is a private fun project created by two students at a coding school. We do not guarantee
					uptime nor we can guarantee data safety. We strongly advise against storing any sensitive data on
					our service. Passwords and pass-phrases belong in your head or in a password manager – not in our
					database. We take no liabilities in any form.
				</h2>
			</div>
		</main>
	);
}
