import React from "react";

export default function TermsAndConditions(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Terms & Conditions for Notaduo</h1>
					<TermsAndConditionsContent />
				</div>
			</section>
		</main>
	);
}

export function TermsAndConditionsContent(): JSX.Element {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 23 December 2023
				</p>
			</div>

			<article>
				<h2>1. Introduction</h2>
				<p>
					These terms of service (“Terms”) apply to your access and use of the Notaduo app and Notaduo web
					version (web.notaduo.com) (the “Service”). Please read them carefully.
				</p>
			</article>

			<article>
				<h2>2. Accepting these Terms</h2>
				<p>
					If you access or use the Service, it means you agree to be bound by all of the terms below. So,
					before you use the Service, please read all of the terms. If you don’t agree to all of the terms
					below, please do not use the Service. Also, if a term does not make sense to you, please let us know
					by e-mailing web.notaduo@gmail.com.
				</p>
			</article>

			<article>
				<h2>3. Changes to these terms</h2>
				<p>
					We reserve the right to modify these Terms at any time. For instance, we may need to change these
					Terms if we come out with a new feature or for some other reason.
				</p>
				<p>
					Whenever we make changes to these Terms, the changes are effective immediately after we post such
					revised Terms (indicated by revising the date at the top of these Terms) or upon your acceptance if
					we provide a mechanism for your immediate acceptance of the revised Terms (such as a click-through
					confirmation or acceptance button). It is your responsibility to check notaduo.com for changes to
					these Terms.
				</p>
				<p>
					If you continue to use the Service after the revised Terms go into effect, then you have accepted
					the changes to these Terms.
				</p>
			</article>

			<article>
				<h2>4. Third-Party Services</h2>
				<p>
					From time to time, we may provide you with links to third party websites or services that we do not
					own or control. Your use of the Service may also include the use of applications that are developed
					or owned by a third party. Your use of such third party applications, websites, and services is
					governed by that party’s own terms of service or privacy policies. We encourage you to read the
					terms and conditions and privacy policy of any third party application, website or service that you
					visit or use.
				</p>
			</article>

			<article>
				<h2>5. Your Content & Conduct</h2>
				<p>
					Notaduo is not responsible for monitoring or moderating the content provided by users, nor does it
					take any responsibility for such content. Any use of the Service to store, share, or distribute
					illegal or inappropriate content is strictly prohibited. Users are solely responsible for ensuring
					that their content complies with all applicable laws and regulations. Notaduo disclaims any
					liability for any illegal or unauthorized content posted, shared, or stored on the platform.
				</p>
				<p>
					Our Service allows you and other users to create and edit notes. You are responsible for the content
					that you make available to the Service, including its legality, reliability, and appropriateness.
					When you create a note, link, or otherwise make content available to the Service, keep in mind that
					your content is publicly available.
				</p>
				<p>
					You guarantee us that all rights on the content you write on notaduo (web.notaduo.com) belong to
					you. You retain all of your rights to the content you post, link, and otherwise make available on or
					through the Service. You can remove the content that you created by disconnecting from it. Once you
					disconnect your note, copies of your deleted note may remain in our system. If you want to ensure
					that we delete it, feel free to contact us anytime via e-mail.
				</p>
				<p>
					You may also delete content yourself by just emptying the note entirely before disconnecting it.
					However, you may not create, link, and otherwise make available on or through the Service any of the
					following:
				</p>
				<ul>
					<li>Content that is libelous, defamatory, bigoted, fraudulent or deceptive.</li>
					<li>Content that is illegal or unlawful, that would otherwise create liability.</li>
					<li>
						Content that may infringe or violate any patent, trademark, trade secret, copyright, right of
						privacy, right of publicity, or other intellectual or other right of any party.
					</li>
					<li>
						Mass or repeated promotions, political campaigning, or commercial messages directed at users who
						do not follow you (SPAM).
					</li>
					<li>
						Private information of any third party (e.g., addresses, phone numbers, email addresses, Social
						Security numbers, and credit card numbers).
					</li>
					<li>Viruses, corrupted data or other harmful, disruptive, or destructive files or code.</li>
				</ul>
				<p>
					In addition, you agree that you will not do any of the following in connection with the Service or
					other users:
				</p>
				<ul>
					<li>
						Use the Service in any manner that could interfere with, disrupt, negatively affect, or inhibit
						other users from fully enjoying the Service or that could damage, disable, overburden, or impair
						the functioning of the Service.
					</li>
					<li>
						Impersonate or post on behalf of any person or entity or otherwise misrepresent your affiliation
						with a person or entity.
					</li>
					<li>
						Collect any personal information about other users, or intimidate, threaten, stalk, or otherwise
						harass other users of the Service.
					</li>
					<li>Create an account or post any content if you are not over 13 years of age.</li>
					<li>
						Circumvent or attempt to circumvent any filtering, security measures, rate limits, or other
						features designed to protect the Service, users of the Service, or third parties.
					</li>
				</ul>
			</article>

			<article>
				<h2>6. Materials</h2>
				<p>
					We put a lot of effort into creating the Service including, the logo and all designs, text,
					graphics, pictures, information and other content (excluding your content). This property is owned
					by us or our licensors and it is protected by U.S. and international copyright laws. We grant you
					the right to use it.
				</p>
				<p>
					However, unless we expressly state otherwise, your rights do not include: (i) publicly performing or
					publicly displaying the Service; (ii) modifying or otherwise making any derivative uses of the
					Service or any portion thereof; (iii) using any data mining, robots or similar data gathering or
					extraction methods; (iv) downloading (other than page caching) of any portion of the Service or any
					information contained therein; (v) reverse engineering or accessing the Service in order to build a
					competitive product or service; or (vi) using the Service other than for its intended purposes. If
					you do any of this stuff, we may terminate your use of the Service.
				</p>
			</article>

			<article>
				<h2>7. Hyperlinks and Third-Party Content</h2>
				<p>
					You may create a hyperlink to the Service. But, you may not use, frame or utilize framing techniques
					to enclose any of our trademarks, logos or other proprietary information without our express written
					consent.
				</p>
				<p>
					Notaduo makes no claim or representation regarding, and accepts no responsibility for third party
					websites accessible by hyperlink from the Service or websites linking to the Service. When you leave
					the Service, you should be aware that these Terms and our policies no longer govern.
				</p>
				<p>
					If there is any content on the Service from you and others, we don’t review, verify or authenticate
					it, and it may include inaccuracies or false information. We make no representations, warranties, or
					guarantees relating to the quality, suitability, truth, accuracy or completeness of any content
					contained in the Service. You acknowledge sole responsibility for and assume all risk arising from
					your use of or reliance on any content.
				</p>
			</article>

			<article>
				<h2>8. Unavoidable Legal Stuff</h2>
				<p>
					THE SERVICE AND ANY OTHER SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
					THE SERVICE ARE PROVIDED TO YOU ON AN AS IS OR AS AVAILABLE BASIS WITHOUT ANY REPRESENTATIONS OR
					WARRANTIES OF ANY KIND. WE DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS (EXPRESS OR IMPLIED,
					ORAL OR WRITTEN) WITH RESPECT TO THE SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
					YOU THROUGH THE SERVICE WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE
					IN THE TRADE, BY COURSE OF DEALING OR OTHERWISE.
				</p>
				<p>
					IN NO EVENT WILL NOTADUO NOR ITS CREATORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL,
					INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION
					WITH THE SERVICE OR ANY OTHER SERVICE AND/OR CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
					THROUGH THE SERVICE, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT LIABILITY
					OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR ARE AWARE OF THE
					POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY FOR ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF
					LIABILITY WILL BE LIMITED TO THE AMOUNT YOU PAID TO NOTADUO. THIS SECTION WILL BE GIVEN FULL EFFECT
					EVEN IF ANY REMEDY SPECIFIED IN THIS AGREEMENT IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
				</p>
				<p>
					You agree to defend, indemnify and hold us harmless from and against any and all costs, damages,
					liabilities, and expenses (including attorneys’ fees, costs, penalties, interest and disbursements)
					we incur in relation to, arising from, or for the purpose of avoiding, any claim or demand from a
					third party relating to your use of the Service or the use of the Service by any person using your
					account, including any claim that your use of the Service violates any applicable law or regulation,
					or the rights of any third party, and/or your violation of these Terms.
				</p>
			</article>

			<article>
				<h2>9. Copyright Complaints</h2>
				<p>
					We take intellectual property rights seriously. In accordance with the Digital Millennium Copyright
					Act (“DMCA”) and other applicable law, we have adopted a policy of terminating, in appropriate
					circumstances and, at our sole discretion, access to the service for users who are deemed to be
					repeat infringers.
				</p>
			</article>

			<article>
				<h2>10. Entire Agreement</h2>
				<p>
					These Terms constitute the entire agreement between you and Notaduo regarding the use of the
					Service, superseding any prior agreements between you and Notaduo relating to your use of the
					Service.
				</p>
			</article>

			<article>
				<h2>11. Feedback</h2>
				<p>
					Please let us know what you think of the Service, these Terms and, in general, Notaduo. When you
					provide us with any feedback, comments or suggestions about the Service, these Terms and, in
					general, Notaduo, you irrevocably assign to us all of your right, title and interest in and to your
					feedback, comments and suggestions.
				</p>
			</article>

			<article>
				<h2>12. Questions & Contact Information</h2>
				<p>
					If you have any questions about this Terms and Conditions, please contact us at:{" "}
					<a href="mailto:web.notaduo@gmail.com">web.notaduo@gmail.com</a>.
				</p>
			</article>
		</div>
	);
}
