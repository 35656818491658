import React from "react"; // Import React
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import useDeviceIsMobile from "../hooks/useDeviceIsMobile"; // Ensure this path is correct

export default function Navigation(): JSX.Element {
    const isMobile = useDeviceIsMobile();
    const location = useLocation();

    // Determine if the path is the root or a specific page
    const isRootPath = location.pathname === "/";
    const onHomePage = location.pathname === "/Home" || location.pathname === "/home";
    const onLegalPage = location.pathname === "/Legal" || location.pathname === "/legal/"  || location.pathname === "/legal";

    return (
        <footer style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '20px'
        }}>
            <nav style={{ marginBottom: '10px' }}>
                {(onLegalPage) && (
                    <Link to="/Home" style={{ margin: '0 10px', color: '#000000' }}>Home</Link>
                )}
                {(onHomePage || isRootPath) && (
                    <Link to="/Legal" style={{ margin: '0 10px', color: '#000000' }}>Legal</Link>
                )}
            </nav>
            <div className="rights" style={{ marginTop: '10px' }}>
                &copy; 2024 Octo Octo. {isMobile && <br />}
                All rights reserved.
            </div>
        </footer>
    );
}
