import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/style.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Navigation from "./components/Footer";
import AboutUs from "./pages/legal/AboutUs";
import Imprint from "./pages/legal/Imprint";
// import Pricing from "./pages/Pricing";
import Legal from "./pages/legal/Legal";
import CookiePolicy from "./pages/legal/CookiePolicy";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
// import Schools from "./pages/Schools";
// import Support from "./pages/Support";
// import Testimonials from "./pages/Testimonials";
// import FAQ from "./pages/FAQ";
import ErrorPage from "./pages/ErrorPage";

export default function App() {
	useEffect(() => {
		// Function to clear specific localStorage items
		const clearAnimationFlags = () => {
			localStorage.removeItem("animationsPlayedHome");
			localStorage.removeItem("animationsPlayedSchools");
		};

		// Add the event listener for beforeunload event
		window.addEventListener("beforeunload", clearAnimationFlags);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener("beforeunload", clearAnimationFlags);
		};
	}, []);

	return (
		<Router>
			<div className="App">
				<div className="left"></div>
				<div className="center">
					<Header />
					<AvailableRoutes />
					<Navigation />
				</div>
				<div className="right"></div>
			</div>
		</Router>
	);
}

function AvailableRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/home" element={<Home />} />
			{/* <Route path="/Schools" element={<Schools />} /> */}
			{/* <Route path="/FAQ" element={<FAQ />} /> */}
			<Route path="/AboutUs" element={<AboutUs />} />
			{/* <Route path="/Pricing" element={<Pricing />} /> */}
			{/* <Route path="/Support" element={<Support />} /> */}
			<Route path="/imprint" element={<Imprint />} />
			<Route path="/Legal" element={<Legal />} />
			<Route path="/CookiePolicy" element={<CookiePolicy />} />
			<Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
			<Route path="/TermsAndConditions" element={<TermsAndConditions />} />
			{/* <Route path="/Testimonials" element={<Testimonials />} /> */}
			<Route path="/404" element={<ErrorPage message="Page not found" />} />
			<Route path="/*" element={<ErrorPage message="Page not found" />} />
		</Routes>
	);
}
