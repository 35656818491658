import * as e from "../enums";

export const LOGO = "/img/logo.png";

export const ICONS: Record<any, string> = {
	[e.Modes.INTERVALS]: "/img/1.png",
	[e.Modes.SCALES]: "/img/2.png",
	[e.Modes.MELODIC_DICTATION]: "/img/3.png",
	[e.Modes.CHORDS]: "/img/4.png",
	[e.Modes.INVERSIONS]: "/img/5.png",
	[e.Modes.PROGRESSIONS]: "/img/6.png",
	[e.Icons.EXERCISE]: "/img/headphones.png",
	[e.Icons.STATISTICS]: "/img/stats.png",
	[e.Icons.SETTINGS]: "/img/settings.png",
	[e.Icons.EXAMS]: "/img/exams.png",
	[e.Icons.EXAM]: "/img/exam.png",
	[e.Icons.STREAK]: "/img/streak.png",
	[e.Icons.STREAK_ZERO]: "/img/streakZero.png",
	[e.Icons.TIMER]: "/img/timer.png",
	[e.Icons.GOLD]: "/img/gold-medal.png",
	[e.Icons.SILVER]: "/img/silver-medal.png",
	[e.Icons.BRONZE]: "/img/bronze-medal.png",
	[e.Icons.KNOWLEDGE]: "/img/knowledge.png",
	[e.Icons.DIFFICULTY]: "/img/difficulty.png",
	[e.Icons.LEARNING_CURVE]: "/img/learningCurve.png",
	[e.Icons.SCORES]: "/img/scores.png",
	[e.Icons.CUP]: "/img/cup.png",
	[e.Icons.CHECK_CORRECT]: "/img/checkCorrect.png",
	[e.Icons.TRACK_PROGRESS]: "/img/track.png",
	[e.Icons.ERROR_404]: "/img/error404.png",
};

export const IMAGES: Record<any, string> = {
	[e.Images.HOME]: "/img/screenshotHome.png",
	[e.Images.GAMEPLAY]: "/img/screenshotGameplay.png",
	[e.Images.STATS]: "/img/screenshotStats.png",
};
