import React from "react";
import { Link } from "react-router-dom";
// import { LOGO } from "../data/constants/images";
// import { useNavigate } from "react-router-dom";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";

export default function Header(): JSX.Element {
	const isMobile = useDeviceIsMobile();
	return <>{isMobile ? <MenuMobile /> : <MenuWeb />}</>;
}

function MenuWeb() {
	// const navigate = useNavigate();
	return (
		<header>
			{/* <img id="logo" alt="logo" src={LOGO} onClick={() => navigate("/")} /> */}
			{/* <nav className="navigation"> */}
				{/* <Link to="/Home">Home</Link> */}
				{/* <Link to="/Pricing">Pricing & Subscription</Link> */}
				{/* <Link to="/Students">For Students</Link> */}
				{/* <Link to="/Schools">For Schools</Link> */}
				{/* <Link to="/FAQ">FAQs</Link> */}
				{/* <Link to="/Support">Support</Link> */}
			{/* </nav> */}
			{/* <nav className="loginSignUp">
				<Link to="" className="login">
					Login
				</Link>
				/
				<Link to="" className="signup">
					Sign Up
				</Link>
			</nav> */}
		</header>
	);
}

function MenuMobile() {
	const [menuOpen, setMenuOpen] = React.useState(false);

	// const navigate = useNavigate();

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const closeMenu = () => {
		setMenuOpen(false);
	};
	return (
		<header>
			{/* <img id="logo" alt="logo" src={LOGO} onClick={() => navigate("/")} /> */}
			{/* hamburgermenu */}
			<div className="hamburger-menu" onClick={toggleMenu}>
				<div className="line" />
				<div className="line" />
				<div className="line" />
			</div>
			<div className="menu-mobile" style={{ display: menuOpen ? "block" : "none" }}>
				<nav className="navigation-mobile">
					<Link onClick={closeMenu} to="/Home">
						Home
					</Link>
					{/* <Link onClick={closeMenu} to="/Schools">
						For Schools
					</Link> */}
					{/* <Link onClick={closeMenu} to="/FAQ">
						FAQs
					</Link> */}
					<Link onClick={closeMenu} to="/Support">
						Support
					</Link>
					{/* <Link onClick={closeMenu} to="" className="login">
						Login
					</Link>
					<Link onClick={closeMenu} to="" className="signup">
						Sign Up
					</Link> */}
				</nav>
			</div>
		</header>
	);
}
