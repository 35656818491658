import React from "react";
import { Link } from "react-router-dom";
import * as image from "../data/constants/images";
import * as e from "../data/enums";

export default function ErrorPage({ message }: { message: string }): JSX.Element {
	return (
		<main>
			<div className="errorPage">
			<img src={image.ICONS[e.Icons.ERROR_404]} alt="Error 404" />
				<div className="errorText">
					<div>
						<h2>Error</h2>
						<p>{message}</p>
					</div>
					<Link to="/">
						<button>Back to home</button>
					</Link>
				</div>
			</div>
		</main>
	);
}
