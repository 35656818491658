import React from "react";

export default function Imprint(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Imprint</h1>
					<ImprintContent />
				</div>
			</section>
		</main>
	);
}

export function ImprintContent(): JSX.Element {
	return (
		<main>
			{/* <h1>About Us</h1> */}
			<div className="card">
				<h2>
				<span>web.notaduo@gmail.com</span>
				</h2>
			</div>
		</main>
	);
}
