import React from "react";

export default function PrivacyPolicy(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Privacy Policy for Notaduo</h1>
					<PrivacyPolicyContent />
				</div>
			</section>
		</main>
	);
}

export function PrivacyPolicyContent(): JSX.Element {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 23 December 2023
				</p>
			</div>
			<article>
				<h2>Our Privacy Policy</h2>
				<p>
					This privacy policy applies to information we collect when you use Notaduo. Notaduo is an
					independent web-based application for note storage and sharing, offering functionalities similar to
					Google Docs, such as public note sharing. However, it operates without the need for user logins, and
					Notaduo is not affiliated with Google in any form. We do not offer user accounts, subscriptions, or
					personal data storage.
				</p>
			</article>
			<article>
				<h2>Collection and Use of Information</h2>
				<p>
					Since Notaduo does not require user accounts, we do not collect personal information like names,
					email addresses, or other contact details. The notes you create and share on Notaduo are considered
					public and can be accessed by anyone. We advise you not to share sensitive or personal information
					in your notes.
				</p>
				<p>
					We use Google Analytics to collect anonymous usage data, such as page views and interactions with
					the service. This data helps us improve the user experience and understand how our services are
					used.
				</p>
			</article>
			<article>
				<h2>Use of Google Firebase</h2>
				<p>
					Notaduo utilizes Firebase, a platform developed by Google, for backend services. Firebase helps in
					managing data storage and other backend functions.
				</p>
			</article>
			<article>
				<h2>Use of Trackers and Cookies</h2>
				<p>
					We use various trackers, including cookies, to enhance your experience on our website. These
					trackers help us analyze how our service is used and provide functionality necessary for our
					service. Users can manage their tracker preferences within their device or browser settings,
					including options to block or delete cookies.
				</p>
				<p>
					For third-party trackers, we recommend reviewing the privacy policies of these services for more
					detailed information. Users can opt out of interest-based advertising through platforms like
					YourOnlineChoices and the Digital Advertising Alliance.
				</p>
			</article>
			<article>
				<h2>Disclaimer for Data Loss or Leaks</h2>
				<p>
					We strive to maintain the integrity and availability of the notes stored on Notaduo. However, we do
					not assume responsibility for any data loss or data leaks. We recommend that users do not store
					sensitive or critical information using our service.
				</p>
			</article>
			<article>
				<h2>Security</h2>
				<p>
					While we take reasonable measures to protect the information on our platform, due to the public
					nature of our service, we cannot guarantee complete security. Users should be aware of the risks
					associated with sharing information online.
				</p>
			</article>
			<article>
				<h2>Changes to This Privacy Policy</h2>
				<p>
					We may update this privacy policy from time to time. We encourage you to review it periodically for
					any changes. Your continued use of Notaduo after any modification to this policy will constitute
					your acceptance of such modification.
				</p>
			</article>
			<article>
				<h2>Contact Us</h2>
				<p>
					If you have any questions about this privacy policy or our use of trackers and cookies, please
					contact us at: <a href="mailto:web.notaduo@gmail.com">web.notaduo@gmail.com</a>.
				</p>
			</article>
		</div>
	);
}
